import './ListOptions.css'

import { Card } from '../../../UI/Card/Card'
import { ListOption } from './ListOption/ListOption'


const ListOptions = (props) => {

    const availableOptions = props.listOptions.map((option) => {
        return <ListOption key={option.optionId} optionId={option.optionId} optionValue={option.value} optionDesc={option.desc} optionStatus={option.optionStatus} setOptionStatus={props.setOptionStatus}/>
    })    

    return (
        <Card className="list-options-container">
            <div className="list-options-button-holder">
            {availableOptions}
            </div>
        </Card>
    )
}

export default ListOptions