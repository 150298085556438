import { Link } from 'react-router-dom'
import './MenuOption.css'
import { MdOutlineAnalytics } from 'react-icons/md'
import { AiOutlineConsoleSql } from 'react-icons/ai'


export const MenuOption = (props) => {

    const getMenuIcon = () => {
        switch(props.text) {
            case 'Pay Data Analyzer':
                return <MdOutlineAnalytics className="menu-icon"/>
            case 'Sequelizer':
                return <AiOutlineConsoleSql className="menu-icon"/>
            default:
                return ;
        }
    }

    return (
        <Link to={props.linkTo} className="menu-option">{getMenuIcon()}<p className="menu-item-text">{props.text}</p></Link>
    )
}