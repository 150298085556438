import styles from './App.module.css'
import { SideMenu } from './components/menu/SideMenu'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { ListConverter } from './components/screens/listconverter/ListConverter'

function App() {


  const AuthenticatedApp = () => {
    return (
      <Switch>
        <Route exact path='/' component={ListConverter} />
      </Switch>
    )
  }
  console.log(styles)
  return (
      <Router>
        <div className={styles.App}>
          <SideMenu />
          <AuthenticatedApp />
        </div>
      </Router>
  )
}

export default App
