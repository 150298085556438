import {  MdLens } from "react-icons/md";
import './ListOption.css'
import { useState } from 'react'

export const ListOption = (props) => {
    const [curStatus,setStatus] = useState('enabled')

    const optionClickHandler = () => {
        curStatus === 'enabled' ? setStatus('disabled') : setStatus('enabled')
        if(curStatus!=='enabled'){
            return props.setOptionStatus(props.optionId, 'enabled')
        }
        return props.setOptionStatus(props.optionId, 'disabled')
    }

    return (
    <button className={`list-option ${curStatus}`} onClick={optionClickHandler}>
        <MdLens className={`icon-${curStatus}`}/>{props.optionDesc}
    </button>
    )
}

