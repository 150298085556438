import ListOptions from './ListOptions/ListOptions'
import ListInput from './ListInput/ListInput'
import ListOutput from './ListOutput/ListOutput'
import { Card } from '../../UI/Card/Card'
import { useState } from 'react'
import './ListConverter.css'
import { processListInput } from '../../utils/ListConverterUtils/processListInput'

export const ListConverter = () => {

    const fetchFromLocalStorage = (key) => {
        if (window.localStorage.getItem(key) === undefined) {
            return []
        }
        return window.localStorage.getItem(key)
    }
    
    const enabledOptions = [
        {
            optionId: 1,
            value: 'addParenthesis',
            status: 'enabled',
            desc: 'Add Parenthesis'
        },
        {
            optionId: 2,
            value: 'addTicks',
            status: 'enabled',
            desc: 'Add Ticks'
        },
        {
            optionId:3,
            value: 'addCommas',
            status: 'enabled',
            desc: 'Add Commas'
        }
    ]
    const [curListData , setNewList] = useState(processListInput(fetchFromLocalStorage('listInput')))
    const [curEnabledOptions, setEnabledOptions] = useState(enabledOptions)
    const updateListData = (newListData) => {
        setNewList(newListData)
    }
    const optionStatus = (optionId, status) => {
        setEnabledOptions(previousEnabledOptions => {
            let newEnabledOptions = previousEnabledOptions
            newEnabledOptions[optionId-1].status = status
            return newEnabledOptions
        })
        setNewList(previousListData => {
            return [...previousListData] //not sure why simply returning previousListData doesn't work? 
        })
    }
    
    return (
        <Card className ='tracker'>
            <ListInput updateListData={updateListData} curListData={curListData}/>
            <ListOptions setOptionStatus={optionStatus} listOptions={curEnabledOptions}/>
            <ListOutput listData={curListData} listOptions={curEnabledOptions}/>
        </Card>
    )
}