import './SideMenu.css'
import { MenuOption } from './MenuOption/MenuOption'
import { MdSpeed } from 'react-icons/md'

export const SideMenu = () => {

    return (
        <div className="side-menu">
            <div className="brand-title">
                <p>List Converter</p><MdSpeed className="brand-title-logo"/>
            </div>
                <MenuOption linkTo="/" text="Sequelizer"/>
        </div>
    )
}