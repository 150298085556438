import './ListInput.css'
import { Card } from '../../../UI/Card/Card'
import { processListInput } from '../../../utils/ListConverterUtils/processListInput'
// import { useLocalStorage } from '../../../utils/ListConverterUtils/useLocalStorage'
import { useState } from 'react'

const ListInput = (props) => {
    const fetchFromLocalStorage = (key) => {
        if (window.localStorage.getItem(key) === undefined || window.localStorage.getItem(key) === null) {
            return []
        }
        return window.localStorage.getItem(key)
    }
    
    const [data, setData] = useState(() => fetchFromLocalStorage('listInput'))

    const handleBlur = (event) => {
        window.localStorage.setItem('listInput', event.target.value)
    }

    const handleChange = (event) => {
        setData(event.target.value)
        let processedListData = processListInput(event.target.value)
        props.updateListData(processedListData)
    }
 
    //useLocalStorage(['listInput',data])

    return (
        <Card className='list-input-container'>
            <h2 className='list-input-header'>Insert List Items:</h2>
            <textarea rows='10' cols='15' onChange={handleChange} onBlur={handleBlur} value={data}></textarea>
        </Card>
    )
}

export default ListInput