import './ListOutput.css'

import { Card } from '../../../UI/Card/Card'


const ListOutput = (props) => {

    let listOptions = props.listOptions

    let filterList = props.listData.filter(listItem => listItem)

    let concatListItems = filterList.map(listItem => {
        if (listOptions[1].status === `enabled`) {
            listItem = `'${listItem}'`
        }
        if (listOptions[2].status === `enabled`) {
            listItem = `${listItem},`
        }
        return listItem
    })

    let concatListToString = concatListItems.join('')

    if(concatListToString.charAt(concatListToString.length - 1) === ',') {
        concatListToString = concatListToString.slice(0,-1)
    }

    if(listOptions[0].status === `enabled`) {
        concatListToString = `(${concatListToString})`
    }

    return (
    <Card className='list-output-container'>
        <h2 className='list-output-header'>List Output:</h2>
        <textarea rows='10' value={concatListToString} readOnly></textarea>
    </Card>
    )
}

export default ListOutput